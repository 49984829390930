.downloads {
    margin-top: pxToRem(64);
}
.downloads__items {
    display: flex;
    gap: pxToRem(28);
    padding: 0 !important;
    margin: 0 !important;

    li {
        padding-left: 0;
        &:before {
            display: none !important;
        }
    }
}