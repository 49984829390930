
.teaser {
    position: relative;



}
.teaser-item__headline {
    margin-top: pxToRem(20);
    padding-bottom: pxToRem(20);
    @include getFigmaStyle('Desktop/H5/Regular/Default');
    color: $color-black;
}

.teaser__item {
    &:nth-child(odd) {
        .teaser-item {
            padding-top: pxToRem(48);
        }
    }
}

.teaser-item {
    position: relative;
    display: block;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        display: block;
        transition: all .6s ease;
        height: 1px;
        background: $color-primary;

    }
    @include media-breakpoint-up(md) {
        &:hover {
            &:after {
                width: 100%;
            }

            .teaser-item__image {

                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);

            }
        }
    }

}

.teaser-item__image {
    transition: all .6s ease;
}
@include media-breakpoint-down(md) {
    .teaser__item {
        &:nth-child(odd) {
            .teaser-item {
                padding-top: pxToRem(0);
            }
        }
    }
}

