$keyvisualHeight: 100vh;

.video.portrait .video__wrap {
    height: 100%;
    // Höhe des Keyvisuals * 1.78 (ergibt sich aus Format 16:9 (16/9) - aufgerundet)
    padding-right: calc($keyvisualHeight * 1.78);
}
.button--booking-kv {
    position: absolute;
    z-index: 40;
    left: 50%;
    bottom: pxToRem(72);
    transform: translateX(-50%);
    padding: pxToRem(27) pxToRem(92) !important;
}
.keyvisual {
    position: relative;
    overflow: hidden;
    height: auto;
    margin-top: $header-height;

    .image {
        position: unset; //reset
    }
    .image img {
        position: absolute; //reset
        height: 100%;
    }
}
.keyvisual__slider, .keyvisual__wrapper {
    height: 100%;
}

.keyvisual--big {
    height: calc(100vh - $header-height);
}

.keyvisual-empty {
    height: $header-height;
}


@include media-breakpoint-down(md) {
    .keyvisual--big {
        // dieser Fall tritt vermutlich nie auf (siehe unten)
        height: calc(100vh - $header-height-mobile);
    }
    .keyvisual {
        margin-top: $header-height-mobile;
    }

    .button--booking-kv {
        width: 100%;
        left: pxToRem(20);
        width: calc(100vw - pxToRem(40));
        bottom: pxToRem(20);
        transform: translateX(0%);
    }
}


@supports (height: 100svh) {
    @include media-breakpoint-down(md) {
        .keyvisual--big {
            height: calc(100vh - #{$header-height-mobile});
        }
    }
}