
.header {
    position: absolute;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: $header-height;
    background: $color-white;
    //background: $color-grey-200;
    border-bottom: 1px solid var(--Primary-300, #F6E1DA);

}


.logo--bildmarke, .header-fixed__btn {
    display: none;
}

.header__logo {
    padding-top: 29px;
}

.header > .container,
.header > .container > .grid {
    height: 100%;
}


.js-nav-scrolled .header {
    transform: translateY(-100%);
    height: 64px;

    .header-fixed__btn {
        display: block;
        margin-right: pxToRem(48);
        margin-top: pxToRem(12);
    }

    .logo--full {
        display: none;
    }

    .logo--bildmarke {
        display: block;

    }

    .header__logo {
        padding-top: 21px;
    }
}


.js-nav-scrolled02 .header {
    transform: translateY(0);
    transition: all 1s ease;
    position: fixed;
}


.container--header {
    display: flex;
    //align-items: center;
    justify-content: space-between;
}

.address--nav {
    margin-top: pxToRem(56);
    font-family: "Montserrat";
    font-size: pxToRem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    color: #fff;
    text-align: center;

    a {
        color: #fff;
    }
}

.address__title {
    margin-bottom: pxToRem(12);
    font-family: "Montserrat";
    font-size: pxToRem(18);
    font-style: normal;
    font-weight: 700;

}

.address__city {
    margin-bottom: pxToRem(12);
}

.address__item {
    display: block;
}

.header__language {
    align-items: center;
    display: flex;

    ul {
        display: flex;
        gap: pxToRem(18);
        li {
            position: relative;
            font-weight: 600;
            text-transform: capitalize;
            &:not(:last-child) {
                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: pxToRem(22);
                    background: $color-primary;
                    position: absolute;
                    right: -9px;
                    top: 3px;

                }

            }
            a {
                color: $font-color;
                font-weight: 300;
            }
        }
    }
}
.header__language--m,.header--ico-m {
    display: none;
}

@include media-breakpoint-down(lg) {
    .header {
        height: 88px;
    }
    .header__logo {
        width: 204px;
    }
    .header__language {
        display: none;
    }
    .header--ico-m {
        display: flex;
        position: absolute;
        right: pxToRem(80);
        top: pxToRem(31);
    }
    .header__language--m {
        display: flex;
        position: absolute;
        left: 28px;
        top: 35px;

        ul {
            li {
                color: #fff;
                a {
                    color: #fff;
                }
            }
        }
    }

    .js-nav-scrolled {
        .header {
            height: 64px;

            .header-fixed__btn {
                margin-right: pxToRem(16);
                font-size: pxToRem(16);
            }
        }

        .header__logo {
            width: 78px;
            margin-left: -0px;
            margin-right: auto;
        }
        .header--ico-m {
            top: pxToRem(19);

        }

        .burger {
            top: 4px;
        }

    }
}