
#CybotCookiebotDialog {
    border-radius: $border-radius !important;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 30%);
}

#CybotCookiebotDialogHeader {
    display: none !important;
}

#CybotCookiebotDialog img {
    width: auto;
}

/**
 * Nav
 */

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    color: $color-primary !important;
    border-bottom: 1px solid $color-primary !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    /* Variante A: sieht schöner aus für dunkle Farben */
    color: $color-primary !important;
    /* Variante B: je nach Farbe evtl. besser lesbar */
    //color: dynamic-color-text($color-white) !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
    color: $color-primary !important;
    //color: dynamic-color-text($color-white) !important;
    border-bottom: 2px solid $color-primary !important;
}

/**
 * Slider Heading
 */

#CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover {
    color: $color-primary !important;
    //color: dynamic-color-text($color-white) !important;
}

/**
 * Slider
 */

#CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color-grey-200 !important;
}

#CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color-primary !important;
}

#CybotCookiebotDialogTabContent input[type=checkbox][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color-grey-200 !important;
}

/**
 * Buttons
 */

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    background-color: $color-white !important;
    color: dynamic-color-text($color-white) !important;
    border: 1px solid $color-primary !important;
    border-radius: $border-radius-button !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: $color-primary !important;
    color: dynamic-color-text($color-primary) !important;
    border: 1px solid $color-primary !important;
    border-radius: $border-radius-button !important;
}

/**
 * Widget
 */

#CookiebotWidget {
    left: 40px !important;
    bottom: 40px !important;
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
    border-color: $color-primary !important;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
    color: $color-primary !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
    fill: $color-primary !important;
}

#CookiebotWidget .CookiebotWidget-logo svg circle {
    fill: $color-primary !important;
}

#CookiebotWidget:not(.CookiebotWidget-open) button.CookiebotWidget-logo {
    width: 48px;
    height: 48px;
    background-image: inline-svg('cookie-bite', $color-white);
    background-size: 20px 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-primary;
    border-radius: 50%;
    transition: all .3s ease;

    &:hover, &:focus {
        background-color: $color-primary-hover;
    }
}

#CookiebotWidget:not(.CookiebotWidget-open) button.CookiebotWidget-logo svg {
    display: none !important;
}

@include media-breakpoint-down(md) {
    #CookiebotWidget {
        left: 10px !important;
        bottom: 10px !important;
    }
}