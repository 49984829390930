.footer {
    background: $color-black;
    color: #fff;
    padding-top: pxToRem(80);
    padding-bottom: pxToRem(80);
}
address, .footer__address {
    font-style: normal;
}
.footer__logo {
    width: pxToRem(262);
    margin-bottom: pxToRem(36);
}
.footer {
    .address__title {
        margin-bottom: pxToRem(20);
        font-weight: 500;
    }
    p.address__item {
        margin-bottom: 0;
        font-weight: 300;
    }
    p.address__city {
        margin-bottom: pxToRem(12);
    }
    a {
        color: #fff;
    }
    .button {
        &:hover {
            background: #fff;
            color: $color-primary !important;
        }
    }
}


.footer__member-hl {
    @include getFigmaStyle('Desktop/H5/Regular/Default');
    margin-bottom: pxToRem(36);

    display: block;
}
.footer__navigation {
    a {
        @include getFigmaStyle('Desktop/MD/Regular/Default');

    }
    ul {
        display: flex;
        flex-wrap: wrap;
        gap: pxToRem(12);
        li {
            flex: 1 1 100%;
        }
    }
}

.footer__zahlung-hl {
    @include getFigmaStyle('Desktop/H6/Medium/Default');
    margin-bottom: pxToRem(20);
}
.footer__zahlung-img {
    width: pxToRem(157);
}


@include media-breakpoint-down(xl) {
    .footer {
        text-align: center;
    }
    .footer__member {
        order: 1;
        margin-bottom: pxToRem(48);

    }
    .footer__navigation {
        order: 2;
        margin-bottom: pxToRem(48);
    }
    .footer__zahlung {
        order: 3;
        margin-bottom: pxToRem(48);
        img {
            margin: 0 auto;
        }

    }
    .footer__address-outer {
        order: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer__logo {
        margin-bottom: pxToRem(48);
    }
}