@import "@sx-core/src/scss/base/button.base.scss";

//DELETE_ON_COPY  @import "@sx-core/src/scss/base/button.base.scss";

.button, .btn {
    border: 0;
    display: inline-flex;
    align-items: center;
    transition: all .6s ease;

    &:hover {
    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.button--primary, .btn--primary,  .button--submit, .btn-submit {
    background: $color-primary;
    //border: 1px solid $color-primary;
    color: #fff !important;
    padding: pxToRem(14) pxToRem(36);
    justify-content: center;
    font-weight: 600;


    &:hover {
        background: $color-secondary-800;
        text-decoration: none;
        color: #fff;
    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.button--secondary {
    background: $color-secondary;

    &:hover {
    }

    &:active {
    }

    &:focus {
    }

    &:disabled, &.disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}
.button--border {
    padding: pxToRem(13) pxToRem(36);
    background: transparent;
    border: 2px solid $color-primary;
    justify-content: center;

    @include getFigmaStyle('Desktop/MD/Medium/Default');
    &:hover {
        background: $color-secondary-800;
        border-color: $color-secondary-800;
        color: #fff;

    }
}
.button--sm {
    padding: pxToRem(7) pxToRem(12);
}
.button--lg {
    padding: pxToRem(26) pxToRem(36);
}

// ggf. inverted , outline , icon

.button-group {
    display: flex;
    gap: pxToRem(16);

    & > .button {
        flex: 0 0 auto;
    }
}

.cta {
    display: flex;
    gap: pxToRem(28);
    margin-top: pxToRem(64);
}

.button--icon, .btn--icon {
    padding: 0 0  0 40px;
    position: relative;


    @include getFigmaStyle('Desktop/MD/Regular/Underline');

    text-transform: none;
    color: $font-color;
    font-size: pxToRem(18);

    &:before  {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        background-color: $color-primary;
        background-image: inline-svg('arrow-right-long', $color-white);
        background-repeat: no-repeat;
        background-position: center;
        width: 28px;
        height: 28px;
        transition: all .5s ease;
        display: block;
        border-radius: 50%;
    }
    &:hover {
        &:before {

            background-color: $color-secondary-800;
        }
    }
}

.button--icon-after, .btn--icon-after  {
    @extend .button--icon;
    padding: 0 40px  0 0;
    &:before  {
        left: unset;
        right: 0;
    }

}
