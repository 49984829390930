@import "@sx-core/src/scss/base/slider.base.scss";
@import "@sx-core/src/scss/base/slider.base.scss";
//DELETE_ON_COPY @import "@sx-core/src/scss/base/slider.base.scss";

.modulspacing.slider {
    margin-top: calc(120px - 40px);
    max-width: 100vw;
    overflow: hidden;
}
.slider-item, .slider__item {
    position: relative;

    &:hover {
        .slider-item__image {
            box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.30);
        }
        &:after {
            width: 100%;
        }
    }
}

.slider-item {
    position: relative;
    padding-bottom: pxToRem(20);
    display: block;
    height: 100%;
    &:after {
        content: '';
        display: block;
        width: 0%;
        height: 1px;
        background: $color-primary;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all .6s ease;
    }
}
.slider-item__image {
    transition: all .6s ease;
}
.slider__slider-item, .slider__item{
    flex-direction: column;
    display: flex;

}
.slider__slider-item {

    flex: 1;
    margin-top: 40px;


}
.slider-item__teasertext {
    margin-top: pxToRem(16);
}
.stoerer--slider {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .stoerer-t1 {
        @include getFigmaStyle('Desktop/XS/Regular/Default');
        margin-bottom: -10px;
        margin-top: -10px;
    }
    .stoerer-t2 {
        @include getFigmaStyle('Desktop/LG/Bold/Default');
        color: #fff;
    }
}
.slider-item__body {
    display: flex;
    position: relative;

    padding-top: pxToRem(28);
    flex-grow: 1;
    flex-direction: column;

    @include getFigmaStyle('Desktop/MD/Regular/Default');
    color: #000;
    .headline--topline {
        margin-bottom: 0;
        @include getFigmaStyle('Desktop/LG/Regular/Default');
    }
    .facts__outer {
        display: flex;

        gap: pxToRem(17);
        margin: pxToRem(12) 0 0 0 ;
    @include getFigmaStyle('Desktop/SM/Medium/Default');


        li {
            position: relative;

            &:not(:last-child):before {
                content: '';
                height: 4px;
                width: 4px;
                top: 10px;
                background: $color-black;
                display: block;
                position: absolute;
                right: -10px;
                border-radius: 50%;
            }
        }

    }
}

.slider-item__headline {
    @include getFigmaStyle('Desktop/H5/Regular/Default');
    color: #000;

}
.slider-item__teasertext {
    //margin-top: auto;
}
.slider__navigation {
    width: 100%;
    height: 0;
}

.splide__arrow--prev,
.splide__arrow--next {
    position: absolute;
    top: calc(50% - 25px);
    display: block;
    width: 72px;
    height: 72px;
    background-color: rgba($color-black, 0.5);
    background-size: 45px 45px;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .6s ease;
    &:hover {
        background-color: rgba($color-primary, 1);
    }
}

.splide__arrow--prev {
    left: 50px;
    transform: translate(0, -50%) rotate(180deg);
    background-image: inline-svg('arrow-big-right-long', $color-white);
}

.splide__arrow--next {
    right: 50px;
    transform: translate(0, -50%);

    background-image: inline-svg('arrow-big-right-long', $color-white);
}

.teaser--slider .teaser__item {
    position: relative;
    height: 100%;
    display: block;
    width: 100%;
}


.slider-item__price {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    width: 80px;
    height: 80px;
    color: #fff;
    background: $color-secondary-800;
    //transform: translate(10px, calc(-100% + 10px));
    display: flex;
    align-items: center;
    justify-content: center;


    @include getFigmaStyle('Desktop/LG/Bold/Default');
    font-weight: 600;

    .price__suffix {
        display: none;

    }
    .price__prefix {
        display: block;
        flex: 1 1 100%;
        text-align: center;
        @include getFigmaStyle('Desktop/XS/Regular/Default');
    }
}
.price-decimals {

}
.keyvisual .splide__arrows {
    display: none;
}


@include media-breakpoint-down(lg) {
    .modulspacing.slider {
        margin-top: calc(72px - 40px);
    }
    .splide__arrows {
        display: none;
    }
    .slider__item {
        width: calc(100vw - 40px - 30px);
        padding-left: 20px !important;
    }
}
@include media-breakpoint-down(md) {
    .slider-item__headline {
        font-weight: 500;
    }
}
