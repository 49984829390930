.infobox {
    background: $color-secondary-800;
    width: 100%;
    color: #fff;
    @include getFigmaStyle('Desktop/MD/Regular/Default');
}
.infobox__top {
    padding: pxToRem(64);
}
.infobox__hl {
    @include getFigmaStyle('Desktop/H1/Regular/Default');
    margin-bottom: pxToRem(48);
}

.infobox__facts {
    margin-top: pxToRem(48);
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        row-gap: pxToRem(24);
        li {
            flex: 0 1 33.3333%;
            padding-right: pxToRem(20);
            padding-left: pxToRem(24);
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 9px;
                left: 6px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $color-primary;
            }
        }
    }
}
.infobox_label-content {
    margin-top: pxToRem(48);
    display: flex;
    width: 100%;
    gap: 20px;
}
.infobox_label-content-content {
    flex: 0 0 calc(33.333% - 10px);

    span {
        display: block;
        @include getFigmaStyle('Desktop/H4/Regular/Default');
        margin-bottom: pxToRem(24);
    }
}
.infobox__button {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: pxToRem(16);
    margin-top: pxToRem(64);

}
.infobox {
    .button--border {
        border-color: #fff;
        color: #fff;
        &:hover {
            background: #fff;
            color: $color-primary;
        }
    }
    .button--lg {
        margin-left: auto;
        &:hover {
            background: #fff;
            color: $color-primary !important;
            border-color: #fff;
        }
    }
}


.infobox__bottom {
    padding: pxToRem(36) pxToRem(64);
    border-top: 1px solid $color-primary;
    display: flex;
    .button--icon-after {
        margin-left: auto;
        color: #fff;
        &:hover {
            &:before {
            background-color: #fff;
                background-image: inline-svg('arrow-right-long', $color-primary);
            }
        }
    }
}
.infobox__bottom-txt {
    padding-right: pxToRem(60);
}

@media (max-width: 1080px) {
    .infobox__button {
        flex-wrap: wrap;
        .button--border {
            flex: 1 1 calc(50% - 8px);
        }
    }
    .infobox {
        .button--lg {
            margin-left: 0;
        }
    }
    .infobox__hl {
        margin-bottom: pxToRem(36);
    }
    .infobox_label-content {
        flex-wrap: wrap;
        gap: pxToRem(36);
    }
    .infobox_label-content-content {
        flex: 1 1 100%;
    }
}


@include media-breakpoint-down(md) {
    .infobox__top {
        padding: pxToRem(36);
    }
    .infobox__button {
        margin-top: pxToRem(48);
    }
    .infobox_label-content {
        margin-top: pxToRem(36);
    }
    .infobox__facts {
        margin-top: pxToRem(36);

        ul li {
            padding-right: 0;
            flex: 1 1 100%;
        }
    }
    .infobox__button {

        .button--border {
            flex: 1 1 100%;
        }
    }
    .infobox {
        .button--lg {
            flex: 1 1 100%;
        }
    }




    .infobox__bottom {
        padding: pxToRem(36);
        flex-wrap: wrap;
        &> * {
            flex: 1 1 100%;
        }
        .button--icon-after {
            flex: 0  0;
            margin-left: 0;
            margin-top: pxToRem(36);
            white-space: nowrap;
        }
    }
}