//the colors must have so much contrast that they work together as a combination of background and font color
$color-neutral-light: $color-neutral-100; // TODO kann -neutral raus? ggf,. stattdessen grey verwenden?
$color-neutral-dark: $color-neutral-1400; // TODO kann -neutral raus? ggf,. stattdessen grey verwenden?

$color-background-primary: lighten($color-primary, 30%) !default; // TODO noch nie benutzt! raus??? (bitte!)
$color-background-secondary: lighten($color-secondary, 30%) !default; // TODO noch nie benutzt! raus??? (bitte!)
$color-background-tertiary: lighten($color-tertiary, 30%) !default; // TODO noch nie benutzt! raus??? (bitte!)


/*Vars for convenience*/
// TODO Diskussion: brauchen wir die hier alle?? Wir haben die noch nie verwndet und eigetlich haben wir aus Figma die -600 -400 etc. Abstufungen
// Unser Wunsch: raus! (ACHTUNG: wird  z.B. noch in einer notify.scss und dashboard.scss benutzt)

$light-percent: 5% !default;
$lighter-percent: 10% !default;
$dark-percent: 5% !default;
$darker-percent: 10% !default;

$color-primary-light: lighten($color-primary, $light-percent) !default;
$color-primary-lighter: lighten($color-primary, $lighter-percent) !default;

$color-primary-dark: darken($color-primary, $dark-percent) !default;
$color-primary-darker: darken($color-primary, $darker-percent) !default;

$color-secondary-light: lighten($color-secondary, $light-percent) !default;
$color-secondary-lighter: lighten($color-secondary, $lighter-percent) !default;

$color-secondary-dark: darken($color-secondary, $dark-percent) !default;
$color-secondary-darker: darken($color-secondary, $darker-percent) !default;

$color-tertiary-light: lighten($color-tertiary, $light-percent) !default;
$color-tertiary-lighter: lighten($color-tertiary, $lighter-percent) !default;

$color-tertiary-dark: darken($color-tertiary, $dark-percent) !default;
$color-tertiary-darker: darken($color-tertiary, $darker-percent) !default;

$color-success-light: lighten($color-success, $light-percent) !default;
$color-success-lighter: lighten($color-success, $lighter-percent) !default;

$color-success-dark: darken($color-success, $dark-percent) !default;
$color-success-darker: darken($color-success, $darker-percent) !default;

$color-warning-light: lighten($color-warning, $light-percent) !default;
$color-warning-lighter: lighten($color-warning, $lighter-percent) !default;

$color-warning-dark: darken($color-warning, $dark-percent) !default;
$color-warning-darker: darken($color-warning, $darker-percent) !default;

$color-danger-light: lighten($color-danger, $light-percent) !default;
$color-danger-lighter: lighten($color-danger, $lighter-percent) !default;

$color-danger-dark: darken($color-danger, $dark-percent) !default;
$color-danger-darker: darken($color-danger, $darker-percent) !default;

$color-info-light: lighten($color-info, $light-percent) !default;
$color-info-lighter: lighten($color-info, $lighter-percent) !default;

$color-info-dark: darken($color-info, $dark-percent) !default;
$color-info-darker: darken($color-info, $darker-percent) !default;