

.article__contentwrap {
    padding-left: pxToRem(108);
}
.article__content {
    max-width: 80%;
}


@include media-breakpoint-down(md) {
    .article__contentwrap {
        padding-left: pxToRem(24);
    }
    .article__content {
        max-width: 100%;
    }
}