@include media-breakpoint-up(lg) {
    .header__navigation {


        ul {
            display: flex;
            gap: pxToRem(48);
        }

        li.level-0 {
            position: relative;

            & > a {
                color: $color-black;
                display: inline-block;
                padding: 39px 0 1px 0;
                transition: all 0.6s ease;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0%;
                    height: 1px;
                    background: $color-primary;
                    transition: all 0.6s ease;
                }

                &:hover {
                    padding: 36px 0 4px 0;

                    &:after {
                        width: 100%;
                    }


                }
            }

            &.active {
                & > a {
                    //text-decoration: underline;
                    padding: 36px 0 4px 0;

                    &:after {
                        width: 100%;
                    }

                }
            }

            &:hover {
                & > ul {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        ul ul {
            display: block;
            position: absolute;
            z-index: 100;
            background: $color-secondary-800;
            padding: 28px 16px;
            top: 104px;
            opacity: 0;
            pointer-events: none;
            transition: all .6s ease;
            width: 290px;
            left: 50%;
            transform: translateX(-50%);

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0px;
                display: block;
                width: 100%;
                height: 42px;
                transform: translateY(-100%);

            }

            &:after {
                content: '';
                position: absolute;
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 14px 12px 14px;
                border-color: transparent transparent #002539 transparent;
                top: -12px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);

            }

        }

        li.level-1 {
            &:not(:last-child) {
            border-bottom: 1px solid $color-primary;
            }

            a {
                color: $color-white;

                padding: 16px 20px 16px 38px;
                display: block;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: 11px;
                    display: block;
                    width: 16px;
                    height: 16px;


                    background-image: inline-svg('arrow-right02', $color-primary);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    transition: all .5s ease;
                }
                &:hover {
                    &:before {
                        left: 13px;
                    }
                }
            }
        }

    }
    .js-nav-scrolled {
        .header__navigation {
            li.level-0 > a {
                padding: 19px 0 1px 0;
                &:hover {
                    padding: 16px 0 4px 0;
                }
            }
            ul ul {
                top: 78px;
            }
        }

    }
}

@include media-breakpoint-down(lg) {
    .js-nav-open {
        overflow: hidden;

        .header__navigation {
            opacity: 1;
            pointer-events: auto;
        }
    }
    .header__navigation {
        background: $color-secondary-800;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100dvh;
        z-index: 200;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        overflow: auto;

        & > ul {
            margin-top: pxToRem(100);
            width: 100%;
        }

        li.level-0 {
            position: relative;
            padding: pxToRem(0) pxToRem(28);
            width: 100%;

            a {
                color: $color-white;
                display: block;
                font-size: pxToRem(20);
                border-bottom: 1px solid $color-primary;
                padding: 22px 0 10px 0;
            }

            & > ul {
                padding-top: pxToRem(16);
                li {
                    a {
                        padding: 22px 0 10px 0;
                    }
                }
            }
        }
        ul ul {
            display: none;
            padding-top: 0 !important;
        }
        li.level-1 {
            a {
                font-size: pxToRem(16);
                border-color: white;
            }
        }

        .plus {
            position: absolute;
            right: pxToRem(28);
            top: pxToRem(17);

            display: block;
            width: 30px;
            height: 30px;
            z-index: 10;
            transition: all .6s ease;

            &:after {
                content: '';
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                background-image: inline-svg('plus', $color-white);
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}


.burger {
    display: none;
    position: absolute;
    right: 10px;
    top: 18px;
    z-index: 600;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background: $color-primary;
}

.header__logo--nav {
    display: none;

    a {
        color: #fff;
    }
}

.address--nav, .button.header-cta--nav {
    display: none;
}

@include media-breakpoint-down(lg) {
    .burger, .header__logo--nav, .address--nav, .button.header-cta--nav {
        display: block;
    }
    .js-nav-open {
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
            background-color: $color-primary !important;
        }
    }
    .js-subnav-open {
        .plus {
            transform: rotate(-225deg);
        }
    }
}