.stoerer {
    position: absolute;
    right: pxToRem(100);
    top: pxToRem(100);
    z-index: 100;
    transition: all .4s ease;
    @include media-breakpoint-down(up) {
        &:hover {
            transform: rotate(5deg);
        }
    }
}

@include media-breakpoint-down(md) {

    .stoerer {
        transform: scale(0.7);
        right: pxToRem(-20);
        top: pxToRem(0);
    }
}