
.accordion-item {
    border-bottom: 1px solid $color-primary;
}

.accordion-item__header {
    cursor: pointer;
    position: relative;
    padding-right: 56px; /* um überlagerung des Icons zu verhindern */
    padding-top: pxToRem(24);
    padding-bottom: pxToRem(24);
    @include getFigmaStyle('Desktop/H4/Regular/Default');
    &:after {
        content: '';
        display: flex;
        position: absolute;
        top: calc(50%);
        right: 16px;
        width: 16px;
        height: 16px;
        background-size: 14px 14px;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: inline-svg('plus', $color-primary);
        transition: transform 0.3s;
    }
}

.accordion__item.uk-open .accordion-item__header:after {
    transform: rotate(225deg);
}
.accordion-item__content {
    padding-top: pxToRem(12);
    padding-bottom: pxToRem(24);

    p:last-child {
        margin-bottom: 0;
    }
}




