.booking-split {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(236, 237, 237, .5);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);

    z-index: 990;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all .6s ease;
    
   a.button:hover {
        background: #fff;
        color: $color-primary !important;
    }

}

.js-booking-split-open {
    .booking-split {
        opacity: 1;
        pointer-events: auto;
    }
    .booking-split__box {
        opacity: 1;
        transition-delay: .5s;
    }
}

.booking-split__box {
    position: relative;
    background: $color-secondary-800;
    width: pxToRem(948);
    color: #fff;
    text-align: center;
    padding: pxToRem(64);
    opacity: 0;
    transition: all .6s ease;
}

.booking-split__logo {
    width: pxToRem(257);
    margin: 0 auto pxToRem(48) auto;

}

.booking-split__hl {
    @include getFigmaStyle('Desktop/H1/Regular/Default');
    margin-bottom: pxToRem(48);
}

.booking-split__split {
    display: flex;


    @include getFigmaStyle('Desktop/H5/Regular/Default');

    span {
        display: block;
        margin-bottom: auto;
        width: 100%;
    }

    .button {

        margin: pxToRem(48) auto 0 auto;

        flex: 0;
        display: inline-flex;
    }
}

.booking-split__book, .booking-split__member {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


}

.booking-split__book {
    position: relative;

    &:after {
        content: '';
        width: 1px;
        height: 100%;
        display: block;
        background: $color-primary;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.booking-split__close {
    width: pxToRem(24);
    height: pxToRem(24);
    position: absolute;
    right: pxToRem(32);
    top: pxToRem(32);
    transform: rotate(-45deg);
    background-image: inline-svg('plus', $color-white);
    background-repeat: no-repeat;
    background-position: center;
    transition: all .6s ease;

    &:hover {
        transform: rotate(-225deg);
    }

}

@media(max-width: 1010px) {
    .booking-split__box {
        //transform: scale(.8);
        width: calc(100vw - pxToRem(40));
    }
    .booking-split__split {
        flex-wrap: wrap;
        font-size: pxToRem(20);
    }
    .booking-split__book, .booking-split__member {
        flex: 1 1 100%;
    }
    .booking-split__book {
        padding-bottom: pxToRem(48);
        margin-bottom: pxToRem(48);
        &:after {
            height: 1px;
            width: calc(100% + pxToRem(72));
            left: pxToRem(-36);
            top: inherit;

            bottom: 0;
        }
    }
    .booking-split__box {
        padding: pxToRem(36);
    }
    .booking-split__logo {
        margin-bottom: pxToRem(36);
        width: 174px;
    }
    .booking-split__hl {
        font-size: pxToRem(32);
        margin-bottom: pxToRem(36);
    }
    .booking-split__split .button {
        margin-top: pxToRem(20);
        font-size: pxToRem(17);
    }
    .booking-split__close {
        right: pxToRem(20);
        top: pxToRem(20);
    }
}

