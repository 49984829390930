.editor ul.facts__outer {
    display: flex;
    margin: 0;
    padding: 0;
    gap: pxToRem(16);

    li {
        margin: 0 0 pxToRem(12) 0;
        padding: 0;

        @include getFigmaStyle('Desktop/SM/Medium/Default');
        position: relative;
        &:before {
            display: none;
        }

    }
    li:not(:last-child) {
        &:after {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            background: $color-black;
            border-radius: 50%;
            position: absolute;
            top: pxToRem(10);
            right: pxToRem(-10)


        }
    }
}
.article__price {
    margin-top: pxToRem(56);
    display: inline-block;
    position: relative;

    @include getFigmaStyle('Desktop/H5/Medium/Default');

    &:before {
        content: '';
        display: block;
        width: pxToRem(60);
        height: 1px;
        background: $color-primary;
        position: absolute;
        top: pxToRem(-28);
        left: 0;
    }
}



// SPECIAL AUSSTATTUNG:

.austattung {

}
.austattung--icons {
    display: flex;
    gap: pxToRem(48);
}
.austattung--icons-row {
    flex: 1 1 calc(33.3333% - 32px);
    display: flex;
    flex-wrap: wrap;
    gap: pxToRem(48);
}
.austattung--icon {
    display: flex;

}
.austattung--icon {
    flex: 1 1 100%;
    display: flex;
}

.austattung--ico {
    flex: 0 1 36px;
    max-width: 36px;
    margin-right: pxToRem(24);
    img {
        width: 36px;
    }
}
.austattung--icon-txt {
    flex: 1;
    @include getFigmaStyle('Desktop/MD/Regular/Default');

}
.austattung--icon-hl {
    @include getFigmaStyle('Desktop/H5/Regular/Default');
    margin-bottom: pxToRem(8);
}


@include media-breakpoint-down(md) {
    .austattung--icons {
        flex-wrap: wrap;
    }
    .austattung--icons-row {
        flex: 1 1 100%;

    }
}

