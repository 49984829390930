@import "../icon/sass-inline-svg";

$checkbox-font-size: $base-font-size !default;
$checkbox-check-size: $base-font-size - 5px !default;
$checkbox-width: pxToRem(28) !default;
$checkbox-height: pxToRem(28) !default;

/**
 * Checkbox container
 */

.checkbox {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    label span {
        @include getFigmaStyle('Desktop/SM/Regular/Default');

    }
}

.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox > label {
    cursor: pointer;
    user-select: none;
    display: flex;

    padding-top: calc($checkbox-height / 6);
    padding-bottom: calc($checkbox-height / 6);
    //line-height: 1.2;
    font-size: $checkbox-font-size;
}

.checkbox.checkbox--small > label {
    font-size: $checkbox-font-size * 0.925;
}

.checkbox.checkbox--large > label {
    font-size: $checkbox-font-size * 1.125;
}

/**
 * Checkbox pseudo checkbox
 */

.checkbox > label input[type="checkbox"] {
    -webkit-appearance: none;
    flex: 0 0 auto;
    display: block;
    border: 1px solid $color-grey-200;
    border-radius: pxToRem(4);
    width: $checkbox-width;
    height: $checkbox-height;
    color: $color-white;
    background-color: transparent;
    background-size: $checkbox-check-size $checkbox-check-size;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid $color-primary;
    transition: background-color 0.1s, border 0.1s;
    margin-right: $checkbox-width*0.5;
    margin-top: $checkbox-width*0.25;
}

.checkbox > label input[type="checkbox"]:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.checkbox > label:hover input[type="checkbox"],
.checkbox > label input[type="checkbox"]:focus,
.checkbox > label input[type="checkbox"]:checked {
    border-color: $color-primary;
}

.checkbox > label input[type="checkbox"]:checked {
    position: relative;
    &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 5px;
        top: 5px;
        background: $color-primary;
    }
}

.checkbox.checkbox--small > label input[type="checkbox"] {
    font-size: $checkbox-check-size * 0.925;
    width: $checkbox-width * 0.925;
    height: $checkbox-height * 0.925;
}

.checkbox.checkbox--large > label input[type="checkbox"] {
    font-size: $checkbox-check-size * 1.125;
    width: $checkbox-width * 1.125;
    height: $checkbox-height * 1.125;
}

.formular-item__checkbox {
    padding-top: $checkbox-font-size*2
}

.col--12-12\@xs .formular-item__checkbox,
.col--12-12\@sm .formular-item__checkbox,
.col--12-12\@md .formular-item__checkbox,
.col--12-12\@lg .formular-item__checkbox {
    padding-top: $checkbox-font-size*0.25;
}

.formular__requiredtext {
    margin-top: $checkbox-font-size;
}

.formular-item--checkbox .fomular-item__error {
    margin-left: $checkbox-width *1.5
}