
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
}

body {
    overflow-x: hidden;
    font-family: $font-family-primary;
    font-weight: $base-font-weight;
    color: $font-color;
    font-size: pxToRem(17);
}

.css-transitions-only-after-page-load *:not(img,picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

a {
    color: $color-primary;
    text-decoration: none;
}
p:not(:last-child) {
    margin-bottom: pxToRem(12);
}

ins {
    text-decoration: none;
}

.modulspacing {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
    }
}

.modulspacing:has(.intermediate:last-child) {
    margin-bottom: 0;
}

.text-center {
    text-align: center;
}

.bgcolorspacing {
    padding-top: $modulspacing-desktop-m;
    padding-bottom: $modulspacing-desktop-m;
    @include media-breakpoint-down(md) {
        padding-top: $modulspacing-mobile-m;
        padding-bottom: $modulspacing-mobile-m;
    }
}
.intermediate + .bgcolor {
    margin-top: 0;
}
.bgcolor {
    background: $color-background;
    padding-top: 1px;
    padding-bottom: 1px;
}
.bgcolor--grey {
    background: $color-secondary-200;

}
.hl-divider {
    display: none;
}

.image__outer {
    .desc {
        @include getFigmaStyle('Desktop/SM/Bold/Default');
        margin-top: pxToRem(16);

    }
}
@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }


}