.gutter--gallery {
    row-gap: 48px;
}
.gallery__item {
    position: relative;

    .image__outer {
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.08);
            opacity: 0;
            transition: all .6s ease;
            z-index: 10;
        }
        &:after {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 12;
            width: pxToRem(40);
            height: pxToRem(40);
            border-radius: 50%;
            position: absolute;
            right: pxToRem(20);
            bottom: pxToRem(20);
            background: $color-primary;
            background-image: inline-svg('plus', $color-white);
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: all .6s ease;

        }
    }
    &:hover {
        .image__outer {
            &:before, &:after {
            opacity: 1;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .gutter--gallery {
        row-gap: 20px
    }
}