
h1, .h1, h2, .h2 {
    @include getFigmaStyle('Desktop/H1/Regular/Default');
    margin-bottom: pxToRem(48);
    max-width: 70%;
}
.topline {
    @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
    display: block;
    margin-bottom: pxToRem(20);

}
@media (max-width: 767px) {
    h1, .h1, h2, .h2 {

        margin-bottom: pxToRem(36);
        max-width: 100%;
    }

}
@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {

    }

    h1, .h1,  h2, .h2 {
        @include getFigmaStyle('Desktop/H1/Regular/Default');
        margin-bottom: pxToRem(48);
        max-width: 70%;
    }
    .topline {
        @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
        display: block;
        margin-bottom: pxToRem(20);

    }

    h2, .h2 {
       // @include getFigmaStyle('HeadingDesktopH1Regular');
    }

    h3, .h3 {
        //@include getFigmaStyle('HeadingDesktopH3Regular');
    }

    h4, .h4 {
        //@include getFigmaStyle('HeadingDesktopH4Regular');
    }

    h5, .h5 {
        //@include getFigmaStyle('HeadingDesktopH5Regular');
    }

    h6, .h6 {
        //@include getFigmaStyle('HeadingDesktopH6Regular');
    }

    p:not(:last-child) {
        margin-bottom: pxToRem(16);
    }

    a {
        color: $color-primary;
        &:hover {
            //text-decoration: underline;
        }
    }

    ul, ol {
        margin: pxToRem(28) 0;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        padding-left: pxToRem(4);
        margin-bottom: pxToRem(8);
    }

    ul {
        padding-left: pxToRem(20);
        list-style-type: none;
    }

    ul li {
        padding-left: pxToRem(4);
        margin-bottom: pxToRem(8);
        position: relative;
    }

    ul li:before {
        content: '\00a0';
        position: absolute;
        top: 0;
        left: -1.2rem;
        display: block;
        width: 1.2rem;
        height: 1.6rem;
        background-image: inline-svg('check', $color-primary);
        background-repeat: no-repeat;
        background-size: 0.8rem 0.8rem;
        background-position: 0 center;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin: pxToRem(8) 0;
    }

    table {
        text-align: left;
        width: 100%;
        margin: pxToRem(64) 0;
    }

    thead tr:last-child {
        border-bottom: 2px solid $color-black;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-grey-200;
    }

    th, td {
        padding: 8px 12px;
    }

    em, i {
        font-style: italic;
    }

    @media (max-width: 767px) {
        h1, .h1,  h2, .h2 {

            margin-bottom: pxToRem(36);
            max-width: 100%;
        }
    }

}