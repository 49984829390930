.faqteaser__outer {

}

.faqteaser {
  &-item {
    margin-bottom: pxToRem(48);
      &:hover {
          .faqteaser__img{
              box-shadow: 0px 4px 36px rgba(0,0,0,0.200);

          }
          .faqteaser__item {
              &:after {
                  width: 100%;
              }
          }

      }

  }

  &__item {
    position: relative;
    border-radius: $border-radius;
    @include getFigmaStyle('Shadow/MD');
      transition: all .5s ease;
    &:before {

            content: '';
            position: absolute;
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 16px 16px 16px;
            border-color: transparent transparent #FAFAFA transparent;
            bottom: -48px;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;


    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 10;
      display: inline-block;
        transition: all .6s ease;

      width: 0%;
      height: 1px;
        background: $color-primary;


    }

    &.show-arrow {
      @include getFigmaStyle('Shadow/LG');
        box-shadow: none;
      &::before {
        opacity: 1;
      }

        &.faqteaser__item {
            &:after {
                width: 100%;
            }
        }


      .faqteaser__body::after {
        background-color: $color-primary-600;
        border-color: $color-primary-1000;
        //background-image: inline-svg('minus', $color-white, transparent);
        transform: rotate(-45deg) scale(.9);
      }
    }
  }

  &__img {
    box-shadow: 0 0 4px rgba($color-black, .1);
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
      transition: all .5s ease;

    .image {
      position: relative;

      &:before {
        content: "";
        display: block;
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
    }
  }

  &__body {
    position: static;
    bottom: 0;
    left: 0;
    width: 100%;
      z-index: 10;
    padding: pxToRem(20) 0 pxToRem(20) 0;




  }

  &-content__title {
    @include getFigmaStyle('Desktop/H5/Regular/Default');
  }
}
.faqteaser__item.show-arrow  {
    box-shadow: none;
}

.item-faq__storage, .item-faq__content {
  display: none;
}

.item-faq__content-outer {

  background-color: #FAFAFA;
  border-radius: $border-radius;
  margin-bottom: pxToRem(48);
    position: relative;



}
.item-faq__content_inner {
    padding: pxToRem(36) pxToRem(92);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
}

.faqteaser-content__headline {
  margin-bottom: pxToRem(16);

  @include getFigmaStyle('Desktop/LG/Medium/Default');
}


@include media-breakpoint-down(lg) {
  .faqteaser {
    &-item {
      margin-bottom: pxToRem(20);
    }
  }

    .faqteaser__item:before {

        bottom: -20px;
    }
    .item-faq__content_inner {
        padding: pxToRem(36);
    }
}


//------------ TEXTTEASER: --------------

.faqteaser-item--textfaq {
  .faqteaser__item {
    background: #fff;
  }

  .faqteaser__body {
    position: static;



  }

  .faqteaser-content__title {
    @include getFigmaStyle('Desktop/LG/Regular/Default');
    color: #000;

  }
}


@include media-breakpoint-down(md) {
    .faqteaser-item .faqteaser__item {
        &:after {
            width: 100% !important;
            opacity: 1;
        }
    }
    .item-faq__content-outer {
        margin-top: 20px;
    }
    .faqteaser__item:before {

        bottom: -40px;
    }
    .item-faq__content_inner {
        padding: pxToRem(36) pxToRem(16);
    }


}

