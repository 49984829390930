/*
  * Use only local fonts!!
  * Preload only woff2 in src/Templates/element/html/head.php
  * Preloader Example: <link rel="preload" href="/src/theme/default/font/***.woff2" as="font" type="font/woff2" crossorigin>
  * IMPORTANT!!!! All webfonts must be converted with https://transfonter.org/ and the option "Fix vertical metrics"
*/

@font-face {
    //https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools
    font-display: swap; //avoid showing invisible text
    //font-family: "???";
    //src: url("/src/theme/default/font/???");

}

@font-face {
    font-family: Montserrat;
    src: url(/dist/font/MontserratAlternates-Medium.eot);
    src: url(/dist/font/MontserratAlternates-Medium.eot?#iefix) format("embedded-opentype"),url(/dist/font/MontserratAlternates-Medium.woff2) format("woff2"),url(/dist/font/MontserratAlternates-Medium.woff) format("woff"),url(/dist/font/MontserratAlternates-Medium.ttf) format("truetype"),url(/dist/font/MontserratAlternates-Medium.svg#MontserratAlternates-Medium) format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url(/dist/font/MontserratAlternates-Regular.eot);
    src: url(/dist/font/MontserratAlternates-Regular.eot?#iefix) format("embedded-opentype"),url(/dist/font/MontserratAlternates-Regular.woff2) format("woff2"),url(/dist/font/MontserratAlternates-Regular.woff) format("woff"),url(/dist/font/MontserratAlternates-Regular.ttf) format("truetype"),url(/dist/font/MontserratAlternates-Regular.svg#MontserratAlternates-Regular) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url(/dist/font/MontserratAlternates-Light.eot);
    src: url(/dist/font/MontserratAlternates-Light.eot?#iefix) format("embedded-opentype"),url(/dist/font/MontserratAlternates-Light.woff2) format("woff2"),url(/dist/font/MontserratAlternates-Light.woff) format("woff"),url(/dist/font/MontserratAlternates-Light.ttf) format("truetype"),url(/dist/font/MontserratAlternates-Light.svg#MontserratAlternates-Light) format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url(/dist/font/MontserratAlternates-Bold.eot);
    src: url(/dist/font/MontserratAlternates-Bold.eot?#iefix) format("embedded-opentype"),url(/dist/font/MontserratAlternates-Bold.woff2) format("woff2"),url(/dist/font/MontserratAlternates-Bold.woff) format("woff"),url(/dist/font/MontserratAlternates-Bold.ttf) format("truetype"),url(/dist/font/MontserratAlternates-Bold.svg#MontserratAlternates-Medium) format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}