.storno {
    background: $color-secondary-800;
    color: #fff;
    padding-top: pxToRem(64);
    padding-bottom: pxToRem(64);
    @include getFigmaStyle('Desktop/MD/Regular/Default');
}
.storno__hl {
    max-width: 90%;
    @include getFigmaStyle('Desktop/H2/Regular/Default');
    margin-bottom: pxToRem(36);
}
.storno__txt {
    padding: pxToRem(48) 0;
}

@include media-breakpoint-down(lg) {
    .storno {
        padding-top: pxToRem(72);
        padding-bottom: pxToRem(72);
        .image__outer {
            margin-bottom: pxToRem(36);
        }
    }
    .storno__hl {
        margin-bottom: pxToRem(20);
    }
    .storno__txt {
        padding: 0;
    }
}