.intermediate {
    height: 40vw;
    overflow: hidden;
    position: relative;
}

@include media-breakpoint-down(md) {
    .intermediate {
        height: 100vw;
    }
}