
[data-filter] [data-hidden] {
    display: none;
}

[data-filter]:not(.has-results) .js-items {
    display: none;
}

.js-items__empty {
    display: none;
}

[data-filter]:not(.has-results) {
    .js-items__empty {
        display: block;
    }

    .js-items__more {
        display: none;
    }
}

.js-items__more {
    margin-top: $spacing-m;
}