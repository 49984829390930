
.systembar {
    font-size: 16px;
    z-index: 900;
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50px;
    background: $color-black;
}

.systembar-spacer {
    display: block;
    width: 100%;
    height: 50px;
}

.systembar-pagetype {
    margin-right: 30px;
}

.systembar-pagetype h4 {
    display: block;
    padding: 0 12px;
    line-height: 50px;
    color: $color-neutral-light;
    font-weight: 500;
}

.systembar ul {
    display: flex;
    flex-direction: row;
}

.systembar ul li:hover {
    background: $color-neutral-dark;
}

.systembar ul li a {
    position: relative;
    display: block;
    padding: 0 12px;
    line-height: 50px;
    color: $color-white;
}

[class*="systembar-icon--"] a {
    padding-left: 32px !important;
    color: $color-grey-800;
}

[class*="systembar-icon--"] a:before {
    content: '\00a0';
    position: absolute;
    top: calc(50% - 7px);
    left: 9px;
    display: block;
    width: 16px;
    height: 16px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
}

.systembar-icon--dashboard a:before {
    background-image: inline-svg('dashboard', $color-grey-700);
}

.systembar-icon--article a:before {
    background-image: inline-svg('article', $color-grey-700);
}

.systembar-icon--formular a:before {
    background-image: inline-svg('formular', $color-grey-700);
}

.systembar-icon--attachment a:before {
    background-image: inline-svg('attachment', $color-grey-700);
}

.systembar-icon--configuration a:before {
    background-image: inline-svg('configuration', $color-grey-700);
}